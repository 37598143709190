.section5 {
    background-color: white;
    margin-top: 2%;
    height: 75%;
    padding-bottom: 5%;
}

.visit-card {
    width: 100%;
    height: 100%;
    margin: auto;
    border: 1px solid transparent;
    box-shadow: 5px 5px 20px #cecdcd;
}

.mtop {
    padding-top: 70px;
}

.delivery {
    width: 100%;
}
