.copyright {
    font-size: 13 px;
}

.footer {
    height: 30%;
    padding: 5% 0%;
    background-color: #cca300;
    font-family: "abel", cursive;
    color: white;
    font-size: 18px;
    text-align: center;
    border-top: 1px solid #cecdcd;
    box-shadow: 2px 2px 10px #cecdcd;
    background-image: linear-gradient(to right, #cca300, #e0bb24);
}
.total{
    width: 100%;
}

.info {
    text-align: center;
    color: #cca300;
    font-size: 20px;
}

.footer-about {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
}

.address {
    text-align: center;
}

.social-media-footer {
    height: 3%;
    width: 200px;
    margin: auto;
}

.social-media-icon {
    color: white;
    font-size: 23px;
    padding: 5px;
}

.social-media-icon-top-wrapper {
    margin-right: 15px;
}
