.section2 {
    background-color: #cca300;
    margin-top: 2%;
    height: 75%;
    padding-bottom: 5%;
    background-image: linear-gradient(to right, #cca300, #fad540);
}

.container-img-waw {
    margin-left: auto;
    margin-right: auto;
    max-width: 500px;
}

.img-waw {
    height: 80%;
    width: 100%;
    border: 1ps solid transparent;
    border-radius: 10px;
}
