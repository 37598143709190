.container {
    z-index: 1000;
    position: relative;
    min-height: 100%;
}

.bg {
    background: url("./../../assets/bg.jpg");
    background-repeat: repeat;
    position: absolute;
    opacity: 0.1;
    z-index: 0;
    width: 100%;
    height: 85%;
}

.top-line {
    background-color: #cca300;
    background-image: linear-gradient(to left, #cca300, #ebcd57);
    height: 40px;
    padding-left: 50%;
}

.social-media {
    height: 3%;
    padding-bottom: 1%;
}

.social-media-icon {
    color: white;
    font-size: 23px;
    padding: 5px;
}

.social-media-icon-top-wrapper {
    margin-right: 15px;
}

.header {
    border-bottom: 1px solid #cecdcd;
    box-shadow: 1px 1px 5px #cecdcd;
    background-image: linear-gradient(to right, transparent, #ece2ba);
}

.menu {
    margin-top: 15px;
    font-size: 13px;
    text-align: "center";
    font-weight: bold;
    font-family: "Abel", sans-serif;
    text-align: center;
}

.logo {
    background: url("./../../assets/logo.png");
    background-size: contain;
    background-repeat: no-repeat;
    width: 50px;
    height: 50px;
    padding-left: 15px;
}

.menu::first-letter {
    color: #cca300;
}

.backToTop {
    line-height: 40px;
    border: 2px solid white;
    border-radius: 5px;
    background-color: #cca300;
    color: white;
    text-align: center;
}

.backToTopLayer{
    padding-right: 150px;
}

.backToTopArrow {
    font-size: 25px;
    padding-top: 10px;
}

.last {
    padding-left: 10%;
    text-align: center;
}

.box-left {
    animation-duration: 2s;
    animation-name: slideinLeft;
}

@keyframes slideinLeft {
    from {
        margin-left: 100%;
    }

    to {
        margin-left: 0%;
    }
}

.box-right {
    animation-duration: 2s;
    animation-name: slideinRight;
}

@keyframes slideinRight {
    from {
        margin-right: 100%;
    }

    to {
        margin-right: 0%;
    }
}
