.img-container {
    position: relative;
}

.section4 {
    background-color: #cca300;
    margin-top: 2%;
    height: 75%;
    padding-bottom: 5%;
    background-image: linear-gradient(to right, #cca300, #ddba2e);
}

.exmp {
    width: 100%;
    height: 400px;
    border: 1px solid transparent;
    border-radius: 5px;
    border: 1px solid transparent;
    box-shadow: 2px 2px 5px #cecdcd;
}

.bottom-left {
    font-family: "abel", cursive;
    position: absolute;
    color: white;
    bottom: 8px;
    left: 16px;
    font-size: 25px;
    font-weight: bold;
}
