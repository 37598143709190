.img-container {
  position: relative;
}

.section4 {
  background-color: #cca300;
  margin-top: 2%;
  height: 75%;
  padding-bottom: 5%;
  background-image: linear-gradient(to right, #cca300, #ddba2e);
}

.exmp7 {
  width: 100%;
  border: 1px solid transparent;
  border-radius: 5px;
  border: 1px solid transparent;
}

.bottom-left {
  font-family: "abel", cursive;
  position: absolute;
  color: white;
  bottom: 8px;
  left: 16px;
  font-size: 25px;
  font-weight: bold;
}

.video-d{
    z-index: 2;
    width: 100%;
}