@import "./../node_modules/antd/dist/antd.min.css";

html,
body,
#root {
  height: 100%;
  width: 100%;
  padding: 0%;
  margin: 0%;
}

.primary-colored {
  color: #cca300;
  font-weight: bold;
}

.center {
  text-align: center;
}

.primary-title {
  color: #cca300;
  font-weight: bold;
  padding-left: 3%;
  font-size: 28px;
  font-family: "Cinzel", serif;
  text-transform: uppercase;
}

.addon-title {
  color: white;
  font-weight: bold;
  padding-left: 3%;
  font-size: 20px;
  font-family: "Cinzel", serif;
  text-transform: uppercase;
}

.primary-wrapper {
  color: #cca300;
  font-size: 30px;
  font-style: italic;
  padding-left: 1%;
  margin-top: -20px;
  font-family: "Tangerine", cursive;
}

.secondary-title {
  color: white;
  font-weight: bold;
  padding-left: 3%;
  padding-top: 3%;
  font-size: 28px;
  font-family: "Cinzel", serif;
  text-transform: uppercase;
}

.secondary-wrapper {
  color: white;
  font-size: 20px;
  padding: 0% 8%;
  font-family: "Abel", sans-serif;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
}

.wrapper-of {
  color: #cca300;
  font-size: 20px;
  padding: 0% 8%;
  font-family: "Abel", sans-serif;
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
}

.divider-paragraph-sec3 {
  color: #cca300;
  font-size: 20px;
  padding: 0% 8%;
  font-family: "Abel", sans-serif;
  margin-left: auto;
  margin-right: auto;
  max-width: 600px;
}

.divider-paragraph-sec3::first-letter {
  font-weight: bold;
}

.contentStyle {

  width:100%;
  height:100%;
}

.contentStyleS {
  height: 300px;
  width: 350px;
  border: none;
  max-width: 600px;
}

.title {
  font-family: "Cinzel", cursive;
  font-weight: bold;
  color: #cca300;
}

.title-secondary {
  font-family: "Cinzel", cursive;
  font-weight: bold;
  color: white;
}

/* ANTDESIGN CUSTOMIZATON */
.ant-modal-body {
  padding: 0;
  margin: 0;
}

.ant-spin-dot-item {
  background-color: #cca300;
}

.ant-modal-content {
  background-color: #cca300;
  border: 1px solid transparent;
  box-shadow: 1px 1px 5px #cecdcd;
  border-radius: 25px;
  margin: auto;
}

.ant-modal-confirm-body .ant-modal-confirm-content {
  color: white;
  font-size: 20px;
  font-family: "abel", cursive;
}

.anticon svg {
  color: white;
}

.ant-btn {
  display: none;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
